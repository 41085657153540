.VedioIframDiv {
  min-height: 100vh;
  min-width: 100vw;
  flex-direction: column;
  display: flex;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  transition: linear 0.3s ease;
}

.animate-slide-to-left {
  animation: slideToLeft 1s ease;
}

.Main_VedioIframeDiv {
  /* height: auto; */
  width: 100vw;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: space-around;
}
.Video_Heading {
  font-size: 2rem;
  font-family: Impact, "Arial Narrow Bold", sans-serif;
  margin: 0;
  color: white;
  text-align: center;
  margin: 10px 0px;
  letter-spacing: 0.1rem;
  word-spacing: 0.2rem;

  /* Apply transition to desired properties */
  transition: font-size 0.3s ease, color 0.3s ease;
}

.Video_Discription {
  font-size: 1rem;
  font-family: sans-serif;
  margin: 4px;
  color: rgb(222, 209, 209);
  text-align: center;
  /* Apply transition to desired properties */
  transition: font-size 0.3s ease, color 0.3s ease;
}

.Video_arrow_left {
  height: 40px;
  transform: rotate(180deg);
  width: 40px;

  color: white;
}
.Video_arrow_right {
  height: 40px;
  width: 40px;

  color: white;
}
.Video_arrow_left:hover,
.Video_arrow_right:hover {
  border-radius: 50%;
  padding: 5px;
}
.arrowmobile {
  display: none;
}

.Blog-div {
  min-height: 70vh;
  justify-content: space-around;
  align-items: center;
  max-width: 700px;
  min-width: 300px;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
}
._Ifram {
  height: 40vw;
  width: 60vw;
  border-radius: 20px;
}



.Videos-List-Div{
  display:flex;
  position:fixed;
  left:40px;
  z-Index:2;
  bottom:50px;
  justify-content:start;
  align-items:baseline;
}

.Videos-List-Div-Show{
  max-height:400px;
  border-radius: 10px;
  background-color:rgba(15, 14, 71, 0.3);
  width:250px;
  display:flex;
  flex-direction:column-reverse;
  overflow:auto;
  backdrop-filter:blur(40px);
  overflow-x:hidden;
position: relative;
}


  .conditionStyle{
    display: none;
  }


  .list-sticky-header{
    backdrop-filter: blur(40px);
  }


@media (max-width: 700px) {
  ._Ifram {
    min-height: 40vw;
    min-width: 80vw;
    height: auto;
    width: auto;
    border-radius: 20px;
  }
}
@media (max-width: 500px) {
  ._Ifram {
    min-height: 30vh;
    min-width: 100vw;
    height: auto;
    width: auto;
    border-radius: 0px;
  }
  .Main_VedioIframeDiv {
    margin-top: 50px;
  }
  .Video_arrow_right {
    display: none;
  }
  .Video_arrow_left {
    display: none;
  }
  .arrowmobile {
    display: block;
    background-color:rgba(15, 14, 71, 0.3);
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
  .mobile-View-Arrows {
    display: flex;
    width: 90vw;
    border-radius: 20px;

    position:fixed;
    bottom:20px;
    z-index: 4;
    padding: 10px;
    justify-content: space-between;
    background-color:rgba(15, 14, 71, 0.3);
    align-items: center;
    backdrop-filter: blur(40px);
  }
  
  .position-fixed-mobile {
    position: fixed;
    top: 0;
    background-color: #6a1b9a;
    bottom: 3px;
    height:300px;
    width: max-content;
    z-index: 1;
  }
  .video-fixed-margin{
    padding-top: 340px;
  }
  .Videos-List-Div{
   
    left: 16px;
    bottom: 95px;
  }
  .VideoListIcon{
display:none
  }
}
@keyframes slideToLeft {
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
