.TextDivComp{ 
    display: flex;
    flex-direction: column;
    max-width: 400px;
    position:relative
 }
 .text-shadow-class{
    text-shadow:
    0 0 10px #fff,
     0 0 20px rgb(140, 22, 138);
     transition: color 0.6s ease;
 }
 .box-shadow-class{
    box-shadow:0 0 10px #fff,
    0 0 20px rgb(140, 22, 138);
    transition: color 0.6s ease;
 }
 @media (max-width:700px){
    .TextDivComp{
        max-width: 80%;
    }
 }