.Courses {
  min-height: 100vh;
  background-color: blueviolet;
}
.Courses-Contents-div {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.Courses-Heading {
  font-size: 40px;
  margin: 0;
  color: white;
  font-family: arial black;
  line-height: 46px;
}
.discriptionTxT {
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  opacity: 0.6;
}
.Courses-Video-div {
  background: rgba(15, 14, 71, 0.3);
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 0.5px inset;
  border-radius: 30px;
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  /* justify-content: center; */
  /* align-items: center; */
  margin-top: 40px;
  padding: 20px;
  width: 55%;
  
}
.Courses-data-div{
  height: 40px;
  width: 375px;
  background-color: rgba(42, 47, 47, 0.13);
  margin: 4px;
  border-radius: 5px;
}
.CourseNavBar{
  width: 70%;
}
@media (max-width:700px) {
  .CourseNavBar{
    width: 100%;
  }
  .Courses-Video-div {

    width: 100%;
    
  }
  .discriptionTxT{
  text-align: center;
  }
  .Courses-Heading{
    text-align: center;
  }
  .lakdlkj{
    align-items: center;
  }
}