.AboutUs {
    /* background: #1F1F47;
    background: linear-gradient(180deg, #1F1F47 0%, rgba(251,66,228,1) 51%, rgba(255,245,247,1) 100%, rgba(213,198,198,1) 100%); */
}

.AboutUss {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: end;
}

.AboutUs1 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 50px 0;
    justify-content: center;

}

.AboutUs2 {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-self: center;
    width: 100%;
    justify-content: center;
}

.contentText {
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 30px;
    z-index: 3;
    color: white;

}

.text1 {
    font-size: 15px;
    font-weight: 600;
    margin: 0;
    color: black;
}

.text2 {
    font-size: 25px;
    margin: 0;
    font-weight: 700;
    color: black;
}

.text3 {
    font-size: 12px;
    color: black;
}

.contentItem {
    height: 100px;
    width: 340px;
    display: flex;
    align-items: start;
    z-index: 3;
}

.course_Div {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LogoStyles {
    height: 3rem;
    width: 3rem;
    cursor: pointer;
}

@media (max-width:700px) {
    .course_Div {
        width: 47px;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}