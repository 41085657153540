
.Courses_card2_main{ 
    justify-content: center;
    align-items: center;
    position: relative;
    height: 488px;
    width: 374px;
    display: flex ;
    border-radius: 20px;
    bottom: 10px;
}


.CoursesCard2{
    height: 90%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transition-duration: 2s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-delay: 0s;
    position: relative;
    /* box-sizing: inherit; */
    border-radius: 30px;

    cursor: pointer;
    transition-property: all;
   
}
.CoursesCard2:hover{
    border-radius: 17px;
    height:100%;
    width: 100%;
    transition-duration: 2s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-delay: 0s;
    transition-property: all;
}
.CoursesCard2:hover .CoursesCard2Img{
   transform: translateY(12deg);
}
.Courses_card2_img{
    height:80%;
    width: 80%;
    margin-top: 20px;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.11, 0.84, 0.27, 1.1);
    transition-delay: 0s;
    transition-property: all;
}
.Courses_card2_img-parent1{
    display: flex;
    justify-content: center;
    align-items: center;
    height:64%;
    width: 70%;
    transition-duration: 0.8s;
    border-radius: 50%;
    transition-timing-function: cubic-bezier(0.11, 0.84, 0.27, 1.1);
    transition-delay: 0s;
    transition-property: all;
    background: linear-gradient(165deg, rgb(98 216 251 / 0%) 13.57%, rgb(60 85 0 / 39%) 98.38%);
}
.Courses_card2_img-parent2{
    display: flex;
    justify-content: center;
    align-items: center;
    height:70%;
    border-radius: 50%;
  margin-top: 30px;
    width: 90%;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.11, 0.84, 0.27, 1.1);
    transition-delay: 0s;
    transition-property: all;
    background: linear-gradient(157.21deg, rgb(98 149 251 / 0%) 13.57%, rgb(21 0 85 / 56%) 98.38%);}
.CoursesCard2:hover .icon-div{
    height: 35px;
    width: 35px;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-delay: 0s;
    transition-property: all;
}

.Star-img{
    height: 20px;
    width: 20px;
    border-radius: 20px;
    background-color:rgba(0, 0, 0, 0.2);
    margin: 20px;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-delay: 0s;
    transition-property: all;
}
.Star-img:hover{
    height: 30px;
    width: 30px;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-delay: 0s;
    transition-property: all;
}

.Courses-card2-Name{
    font-size: 0.8rem;
    font-weight: 800;
    color: white;
    transition: font-size 0.3s ease, color 0.3s ease;
    margin: 4px;
}
.Courses-card2-discription{
    color: white;
    font-size: 13px;
    opacity: 0.6;
    align-self: center;
    margin: 0px;
    text-align: center;
    transition: font-size 0.3s ease;
    max-width:200px;
    /* margin-left: 30px; */
}
.icon-div{
    height:27px;
    width:27px;
    margin-top:4px;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-delay: 0s;
    transition-property: all;
}
.CoursesCard2Img-div{

    align-items:flex-start;
    justify-content:space-between;
    display:flex;
    position: relative;
    top: 10px;
    width: 90%;
}
#icon_div{
    height:35px;
    width:35px
}

.Course_Card2_Icon{
    height:80px;
    bottom: 14px;
    position: relative;
}
/* @media (max-width:700px){
    .CoursesCard2{
        border-radius: 15px;
    }
.Courses_card2_main{ 
    justify-content: space-around;
    align-items: center;
    height: 200px;
    width: 140px;
    display: flex;
    left: 0;
    border-radius: 10px;
}
.CoursesCard2Img-div{
    display: flex;
    width: 100%;
    margin-bottom: 10px;

}
.Courses_card2_img{
    margin-left: 35px;
    width: 43%;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.11, 0.84, 0.27, 1.1);
    transition-delay: 0s;
    transition-property: all;
}
.icon-div{
    height:20px;
    width:20px;
    margin-bottom:10px
}
#icon_div{
    height:20px;
    width:20px
}
.Courses-card2-Name{
    font-size: 0.5rem;
    margin: 0;
    font-weight: 800;
    color: white;
}
.Courses-card2-discription{
    color: white;
    font-size: 0.48rem;
    opacity: 0.6;
    align-self: center;
    margin: 0px;
    max-width:70%;
}

.CoursesCard2:hover .icon-div{
    height: 25px;
    width: 25px;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-delay: 0s;
    transition-property: all;
}
.Star-img:hover{
    height: 25px;
    width: 25px;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-delay: 0s;
    transition-property: all;
}
.Course_Card2_Icon{
    position: relative;
    right: 5px;
    top:5px;
    height: 50px;
}

} */