/* .book-shape-div{
    height: 100vh;
    width: 100vw;
    margin: 0px;
    overflow-y: hidden;
    background: rgb(255,0,241);
    background: linear-gradient(234deg, rgba(229,2,227,1) 26%, rgba(90,64,168,1) 74%, rgba(208,247,255,1) 100%);
    
} */
.book-shape-div{
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    width:100vw;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    overflow-x: hidden;
    background: rgb(113,112,126);
    background: linear-gradient(125deg, rgba(113,112,126,1) 0%, rgba(121,9,117,0.6727065826330532) 51%, rgba(208,247,255,1) 100%);
    
      /* border-radius: 60% 40% 40% 20% / 70% 50% 30% 25%;
       */
      
     /* flex-wrap: ; */
}
.book-shape{
    padding: 10px;
    height: 270px;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: 12px; */
    
    box-shadow: rgba(0, 0, 0, 0.25) 0px 40px 80px, rgba(255, 255, 255, 0.15) 0px 0px 0px 0.5px inset;
    position: absolute;
    backdrop-filter: blur(5px);
    border-radius: 0px 40px 40px 40px;
    background-image: radial-gradient(218.51% 281.09% at 100% 100%, rgba(253, 63, 51, 0.6) 0%, rgba(76, 0, 200, 0.6) 45.83%, rgba(76, 0, 200, 0.6) 100%);
    transform: skewY(0deg) translateY(0px);
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.book-shape-back{
    padding: 20px;
    height: 270px;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 40px 80px, rgba(255, 255, 255, 0.15) 0px 0px 0px 0.5px inset;
    border-radius: 0px 30px 30px 30px;
    transform: skewY(8deg) rotateY(15deg);
    transform-origin: left top;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-delay: 0s;
    transition-property: all;
    background-image: radial-gradient(218.51% 281.09% at 100% 100%, rgba(253, 63, 51, 0.6) 0%, rgba(76, 0, 200, 0.6) 45.83%, rgba(76, 0, 200, 0.6) 100%);

}

.Book-card-parent-div{
    display: flex;
    margin: 20px; 
    z-index: 2;
}
.Book-card-parent-div:hover .book-shape-back {
    transform: skewY(14deg) rotateY(30deg) translate3d(-2px, 5px, 5px);
    transform-origin: left top;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-delay: 0s;
    transition-property: all;
    /* position: absolute; */
}
.Book-card-parent-div:hover .book-shape {
    transform: skewY(0deg) translateY(-5px);
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    /* position: absolute; */
}




.book-shape-Name{
    font-size: 1.01rem;
    color: white;
    font-weight: bold;
    text-align: center;
}
.book-shape-description{
    font-size: 0.74rem;
    font-weight: 400;
    color: white;
    width: 90%;
    margin-left: 10px;
    text-align: center;

}


.book-shape-feature-icon{
    height: 17px;
    width: 17px;
    background-color: rgb(154, 53, 141);
    padding: 4px;
    border-radius: 10px;

}
.book-shape-features-list{
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin: 5px;
}
.book-shape-features-text{
    font-size: 13px;
    color: white ;
    opacity: 0.7;
    font-weight: 100;
}
