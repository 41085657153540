.Whoisbehind{
    min-height: 66vh;
}
.WhoisbehindTxtdiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;
    justify-content: space-between;
}
.WhoisbehindIconDiv{
    display: flex;
    z-index: 3;
    flex-wrap: wrap;
    width: 410px;
    /* flex-direction: row; */
}

.Whoisbehind3-profile-div{
    left: 90px;
}

.whoisbehind-bg{
    /* background-image: url("../../../assets/images/backgroundShape2.svg"); */
    display: flex;
    width: 90vw;

}
.ProfileDiv{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    /* position: relative; */
}
@media(max-width:700px){
    
.Whoisbehind3-profile-div{
position: relative;
justify-content: center;
right: 100px;
left: 0px;
}
/* .WhoisbehindTxtdiv{   
    left: -10px;
} */
.Whoisbehind{

    /* width: 100vw; */
}
.Whoisbehind{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 100vw; */
}
.profile-div-responsive{
    left: 30px;
    position: relative;
}

}