.Footer {
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
}

.footerEmptySection {
    height: 100px;
}

.footerSection1 {
    flex: 1.75;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 100px;
}

.footerSection1Child1 {
    height: 100%;
    width: 323px;
    /* background-color: #ff0000; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;

}


.footerlogo {
    height: 100px;
    width: 120px;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;

}
/* .footerlogo:hover{
    height: 100%;
    width: 100%;
    transition: .5s;
} */

.Logo2 {
    height: 100%;
    width: 100%;
}

.linkedIcons {
    height: 60px;
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;

}

.icons {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    overflow: hidden;

}




.footerSection1Child2 {
    height: 100%;
    width: 323px;
    /* background-color: #a12121; */
    padding: 0 30px;
}

.ul {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-left: 1em;
}

.li {
    font: 600;
    font-size: 19px;
    text-decoration: none;
    color: #FFF;
    list-style: none;
}

.h1 {
    color: #FFF;
    text-align: center;
    /* margin-left: -71px; */
    padding-bottom: 20px;

}







.footerSection1Child3 {
    height: 100%;
    width: 323px;
    /* background-color: #762b2b; */
}

.location {
    height: 60px;
    width: 100%;
    /* background-color: red; */
    display: flex;
    align-items: center;
}

.locationIcon {
    height: 50px;
    width: 70px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.location p {
    color: #FFF;
    margin: 0 10px;
}



.Logo3 {
    height: 30px;
    width: 30px;
}


.footerSection1Child4 {
    height: 100%;
    width: 323px;
    /* background-color: #482828; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 55px 15px;
}

.inputFiled {
    height: 70px;
    width: 95%;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #fff; */
    overflow: hidden;
}

.input {
    height: 100%;
    flex: 1;
    /* background-color: red; */
}

.InputIcon {
    height: 100%;
    flex: .2;
    background-color: blue;

}

input {
    height: 100%;
    width: 100%;
    padding: 0 20px;
}

.footerSection2 {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #FFF;
}
.footerSection2 p{
    font-size: 17px;
    color: #FFF;

}



@media (max-width: 1200px) {
    .footerSection1 {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 530px;
        }
    .Footer {
        width: 100%;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* background-color: red; */
    }
    .footerEmptySection {
        height: 140px;
    }
}


@media (max-width: 992px) {
    .footerSection1 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        height: 100%;
        width: 500px;
        margin-top: 120px;
    }

    .footerEmptySection {
        height: 100px;
    }
    .h1{
        color: #FFF;
        text-align: center;
        /* margin-left: -114px; */
        padding-bottom: 20px;
    }
}
@media (max-width:700px){
    .location{
        margin-left: 40px;
    }
}